/* Hide the spinner controls for the number inputs */
.otp-input::-webkit-outer-spin-button,
.otp-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.otp-input[type='number'] {
    -moz-appearance: textfield; /* Firefox */
}
